export default {
  methods: {
    formatEditData(isIssue) {
      const form = this.formValue;
      //引き渡し担当者名の再設定
      if (!form.manifestInfo.generateMember.name && !form.manifestInfo.generateMember.id) {
        delete form.manifestInfo.generateMember
      } else {
        if (this.isGenerateMemberSelect) {
          const member = this.memberDisOpt.find(e => e.id === form.manifestInfo.generateMember.id)
          form.manifestInfo.generateMember = member ? { id: member.id, name: member.name } : null
        } else {
          form.manifestInfo.generateMember.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(form.manifestInfo.generateMember.name))
          form.manifestInfo.generateMember.id = null
          if (!form.manifestInfo.generateMember.name) {
            delete form.manifestInfo.generateMember
          }
        }
      }

      //登録担当者名の再設定
      if (!form.manifestInfo.registMember.name && !form.manifestInfo.registMember.id) {
        delete form.manifestInfo.registMember
      } else {
        if (this.isRegistMemberSelect) {
          const member = this.memberOpt.find(e => e.id === form.manifestInfo.registMember.id)
          form.manifestInfo.registMember = member ? { id: member.id, name: member.name } : null
        } else {
          form.manifestInfo.registMember.name = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(form.manifestInfo.registMember.name))
          form.manifestInfo.registMember.id = null
          if (!form.manifestInfo.registMember.name) {
            delete form.manifestInfo.registMember
          }
        }
      }

      // 数量確定者は設定できないものであれば、リセットする
      if (form.wasteInfo.quantityConfirmBusinessTypeId
        && !this.confirmMemberOpt.some(confirmMemberOpt => confirmMemberOpt.id === form.wasteInfo.quantityConfirmBusinessTypeId)){
        form.wasteInfo.quantityConfirmBusinessTypeId = null;
      }

      // 最初の整形
      const initFormating = () => {
        return {
          manifestEditTypeId: isIssue ? 2 : 1, // 2は交付、1は予約編集
          manifestEditInfo: {
            collectionDate: form.manifestInfo.collectionDate,
            collectionTime: form.manifestInfo.collectionTime,
            registMember: form.manifestInfo.registMember,
            generateMember: form.manifestInfo.generateMember,
            routeId: form.manifestInfo.routeId,
            conversionFactorKg: form.manifestInfo.conversionFactorKg,
            conversionFactorM3: form.manifestInfo.conversionFactorM3,
            conversionFactorLiter: form.manifestInfo.conversionFactorLiter,
            conversionFactorUnitNumber:
              form.manifestInfo.conversionFactorUnitNumber,
            attachedFilePath: form.manifestInfo.attachedFilePath, 
            attachedFileName: form.manifestInfo.attachedFileName,
          },
          contactNoList: form.contactNoInfo
            .filter((cn) => cn.contactNo !== "")
            .map((contactNo) => {
              return { contactNo: contactNo.contactNo };
            }),
          collectCost: form.collectCost,
          disposalCost: form.disposalCost,
          valuablesCost: form.valuablesCost,
          wasteEditInfo: {
            jwnetItemTypeId: form.routeInfo.jwnetItem.id,
            wasteNameId: form.wasteInfo.wasteNameId,
            detailWasteName:
              form.wasteInfo.detailWasteName !== null
                ? this.toFullWidthString(form.wasteInfo.detailWasteName)
                : null,
            wasteQuantity: form.wasteInfo.wasteQuantity,
            wasteUnitTypeId: form.wasteInfo.wasteUnitTypeId,
            quantityConfirmBusinessTypeId:
              form.wasteInfo.quantityConfirmBusinessTypeId,
            shapeTypeId: form.wasteInfo.shapeTypeId,
            shapeQuantity: parseInt(form.wasteInfo.shapeQuantity),
            disposalWayTypeId: form.wasteInfo.disposalWayTypeId,
          },

          // harmfulInfoのharmfulTypeIdがnullなら削除
          harmfulEditList: form.harmfulList
            .filter((hf) => hf.id)
            .map((harmful) => {
              return {
                id: harmful.id,
              };
            }),
          labelEditInfo: {
            labels: [...form.labelInfo.manifestLabels],
          },
          remarksEditInfo: {
            remarksList: form.remarksInfo
              .filter((r) => r.detail !== "")
              .map((remarksInfo) => {
                return { remarks: this.toFullWidthString(remarksInfo.detail) };
              }),
          },
          directInput: form.directInput,
        };
      };

      // その他項目追加
      const addOther = (_formattedData) => {
        // コピーしておく
        const formattedData = JSON.parse(JSON.stringify(_formattedData));

        return new Promise((resolve) => {
          // 引渡し日の時間指定なし
          if (formattedData.manifestEditInfo.collectionTime === "") {
            delete formattedData.manifestEditInfo.collectionTime;
          }

          const formattedDataDropHarmfulsIfNeeded = !this
            .isDisplayHarmfulSelection
            ? {
                ...formattedData,
                harmfulEditList: [],
              }
            : formattedData;

          let anyGenerateStoreInfo;
          if (this.routeDetailInfo.isChangeStore && form.anyGenerateStoreInfo) {
            anyGenerateStoreInfo = { ...form.anyGenerateStoreInfo };

            if (anyGenerateStoreInfo.anyGenerateStoreName.length === 0) {
              delete anyGenerateStoreInfo.anyGenerateStoreName;
            }

            if (
              anyGenerateStoreInfo.anyGenerateStoreAddress.address.length ===
                0 &&
              anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length ===
                0 &&
              anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId
                .length === 0 &&
              anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName
                .length === 0
            ) {
              delete anyGenerateStoreInfo.anyGenerateStoreAddress;
            }
            // 任意事業場名を全角に置換する
            if (
              anyGenerateStoreInfo.anyGenerateStoreName &&
              anyGenerateStoreInfo.anyGenerateStoreName.length
            ) {
              anyGenerateStoreInfo.anyGenerateStoreName =
                this.toFullWidthString(
                  anyGenerateStoreInfo.anyGenerateStoreName
                );
            }
            if (
              anyGenerateStoreInfo &&
              anyGenerateStoreInfo.anyGenerateStoreAddress &&
              anyGenerateStoreInfo.anyGenerateStoreAddress.address.length
            ) {
              anyGenerateStoreInfo.anyGenerateStoreAddress.address =
                this.toFullWidthStringForAddressInfo(anyGenerateStoreInfo.anyGenerateStoreAddress.address);
            }
            if (
              anyGenerateStoreInfo &&
              anyGenerateStoreInfo.anyGenerateStoreAddress &&
              anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length
            ) {
              anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName =
                this.toFullWidthStringForAddressInfo(anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName);
            }
          } else {
            anyGenerateStoreInfo = null;
          }

          const formattedDataWithAnyGenerateStoreIfNeeded = anyGenerateStoreInfo
            ? {
                ...formattedDataDropHarmfulsIfNeeded,
                anyGenerateStoreInfo: anyGenerateStoreInfo,
              }
            : formattedDataDropHarmfulsIfNeeded;

          resolve(formattedDataWithAnyGenerateStoreIfNeeded);
        });
      };

      // 実行
      return new Promise((resolve) => resolve(initFormating()))
        .then(addOther);
    },
  },
};
