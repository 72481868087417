<template>
  <div>
    <div class="l-container l-area">
      <ManifestProgressBar
        class="l-box"
        :manifestStatusId="formValue.manifestInfo.manifestStatusInfo.id"
      />
      <ToastMessage :toastMsg.sync="toastMsg" />
      <ErrorMessage :errMsgs="errMsgs" />

      <div class="c-form__reqTxt l-item">：入力必須</div>
<div v-if="isIssueDeadlineWarning || isIssueDeadlineAlert" class="l-contents">
        <template>
      <div
        v-if="isIssueDeadlineWarning"
        class="c-text icon warning"
      >
                    交付期限間近
          </div>
          <div v-else-if="isIssueDeadlineAlert" class="c-text icon danger">
            交付期限超過
          </div>
        </template>
      </div>
      <div v-if="isCloseToExpire" class="l-contents">
        <div class="c-text icon warning">
          マニフェスト期限 : {{ formValue.manifestInfo.expirationDate | dateJpMDddFormat }}
        </div>
      </div>

      <!-- マニフェスト情報 -->
      <div class="l-contents">
        <div class="l-box">
          <h3 class="c-lead icon">マニフェスト情報</h3>
        </div>
        <div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>マニフェスト番号</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                {{ formValue.manifestInfo.manifestNo }}
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>引き渡し日</label >
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols l-area">
                <InputDate
                  :value.sync="formValue.manifestInfo.collectionDate"
                  :placeholder="'0000/00/00'"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.collectionDate.isValid"
                  :errMsg.sync="validate.collectionDate.errMsg"
                />
                <InputTime
                  class="sm"
                  :value.sync="formValue.manifestInfo.collectionTime"
                  :placeholder="'00:00'"
                  :validation="validateJustBlank"
                />
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>交付日</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                {{ formValue.manifestInfo.issueDate }}
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>担当者</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>登録担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols">
                    <div class="c-radio">
                      <input
                        id="regist-member-select"
                        type="radio"
                        :value="true"
                        v-model="isRegistMemberSelect"
                        @change="resetRegistMemberForm()"
                      />
                      <label class="c-radio__label" for="regist-member-select">
                        <span class="c-radio__box"></span>リストから選択
                      </label>
                    </div>
                    <div class="c-radio">
                      <input
                        id="regist-member-input"
                        type="radio"
                        :value="false"
                        v-model="isRegistMemberSelect"
                        @change="resetRegistMemberForm()"
                      />
                      <label class="c-radio__label" for="regist-member-input">
                        <span class="c-radio__box"></span>直接入力
                      </label>
                    </div>
                  </div>
                  <Selectbox
                    v-if="isRegistMemberSelect"
                    class="c-select-input"
                    name="register-in-charge"
                    :value.sync="formValue.manifestInfo.registMember.id"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.registMember.isValid"
                    :errMsg.sync="validate.registMember.errMsg"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in memberOpt"
                      :key="'mp' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <InputText
                    v-if="isRegistMemberSelect == false"
                    class="c-select-input"
                    style="width: 22rem"
                    inputType="text"
                    input_id="input_registerMember"
                    :value.sync="formValue.manifestInfo.registMember.name"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLenWithoutOnlyWhitespace"
                    :validateArg="12"
                    :isValid.sync="validate.registMember.isValid"
                    :errMsg.sync="validate.registMember.errMsg"
                  />
                </div>
              </div>
              <!-- /.c-inputWra -->
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>引き渡し担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols">
                    <div class="c-radio">
                      <input
                        id="collection-select"
                        type="radio"
                        :value="true"
                        v-model="isGenerateMemberSelect"
                        @change="resetGenerateMemberForm()"
                      />
                      <label class="c-radio__label" for="collection-select">
                        <span class="c-radio__box"></span>リストから選択
                      </label>
                    </div>
                    <div class="c-radio">
                      <input
                        id="collection-input"
                        type="radio"
                        :value="false"
                        v-model="isGenerateMemberSelect"
                        @change="resetGenerateMemberForm()"
                      />
                      <label class="c-radio__label" for="collection-input">
                        <span class="c-radio__box"></span>直接入力
                      </label>
                    </div>
                  </div>
                  <Selectbox
                    v-if="isGenerateMemberSelect"
                    class="c-select-input"
                    name="emit-in-charge"
                    :value.sync="formValue.manifestInfo.generateMember.id"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.generateMember.isValid"
                    :errMsg.sync="validate.generateMember.errMsg"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in memberDisOpt"
                      :key="'mp' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <InputText
                    v-if="isGenerateMemberSelect == false"
                    class="c-select-input"
                    inputType="text"
                    input_id="input_generateMember"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLenWithoutOnlyWhitespace"
                    :validateArg="12"
                    :value.sync="formValue.manifestInfo.generateMember.name"
                    :isValid.sync="validate.generateMember.isValid"
                    :errMsg.sync="validate.generateMember.errMsg"
                  />
                </div>
              </div>
              <!-- /.c-inputWra -->
              <div v-if="formValue.manifestInfo.proxyCollectMember != null" class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬代理登録担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ formValue.manifestInfo.proxyCollectMember.name }}
                </div>
              </div>
              <div v-if="formValue.manifestInfo.proxyDisposalMember != null" class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分代理登録担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ formValue.manifestInfo.proxyDisposalMember.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>運搬費用（総額）</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  :value.sync="formValue.collectCost"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.collectCost.isValid"
                  :errMsg.sync="validate.collectCost.errMsg"
                />
                <span>円</span>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分費用</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  :value.sync="formValue.disposalCost"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.disposalCost.isValid"
                  :errMsg.sync="validate.disposalCost.errMsg"
                />
                <span>円</span>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有価売却額</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  :value.sync="formValue.valuablesCost"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.valuablesCost.isValid"
                  :errMsg.sync="validate.valuablesCost.errMsg"
                />
                <span>円</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.l-contents -->
      <!-- 廃棄物情報 -->
      <div class="l-contents l-area">
        <div class="l-box">
          <h3 class="c-lead icon">廃棄物情報</h3>
        </div>
        <div>
          <!-- ルート設定しない場合-->
          <div class="c-inputWrap">
            <div class="c-inputWrap__label" :class="{required: isAbleChangeRoute}">
              <label>ルート</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="non_side_layout" :class="{disabled: !isAbleChangeRoute}">
                <div
                  class="c-btn secondary small l-item"
                  @click="showRouteSelectModal()"
                >
                  ルート選択
                </div>
              </div>
              <p v-if="validate.route.errMsg" class="c-input__errMsg">{{ validate.route.errMsg }}</p>
              <div v-if="formValue.manifestInfo.routeId">
                <dl class="c-dl">
                  <dt>廃棄物種類：</dt>
                  <dd>{{ jwnetItemTypeName }}</dd>
                </dl>
                <dl class="c-dl">
                  <dt>ルート名称：</dt>
                  <dd>{{ routeDetailInfo.routeName }}</dd>
                </dl>
                <dl class="c-dl">
                  <dt>排出事業場：</dt>
                  <dd>{{ routeCompanyName.generateStore }}</dd>
                </dl>
                <dl class="c-dl">
                  <dt>運搬業者：</dt>
                  <dd>{{ routeCompanyName.collectCompany }}</dd>
                </dl>
                <dl class="c-dl">
                  <dt>処分場：</dt>
                  <dd>{{ routeCompanyName.disposalSite }}</dd>
                </dl>
                <dl v-if="routePriorConsultationIsLimit" class="c-dl">
                  <span class="c-text icon icon-before danger">{{ routePriorConsultationAlertMsgs }}</span>
                </dl>
                <dl v-if="routePriorConsultationIsAlert" class="c-dl">
                  <span class="c-text icon icon-before warning">{{ routePriorConsultationAlertMsgs }}</span>
                </dl>
              </div>
            </div>
          </div>

          <!-- 事業場変更可能フラグがONのルートを選択時   -->
          <template v-if="routeDetailInfo.isChangeStore">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>排出事業場名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreName"
                    inputTyle="text"
                    tooltip="60文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="60"
                    :isValid.sync="validate.anyGenerateStoreName.isValid"
                    :errMsg.sync="validate.anyGenerateStoreName.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>排出事業場住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode"
                        inputTyle="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="validate.zipCode.isValid"
                        :errMsg.sync="validate.zipCode.errMsg"
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandler()"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId"
                        :validation="validateJustBlank"
                        :isValid.sync="validate.prefecturesId.isValid"
                        :errMsg.sync="validate.prefecturesId.errMsg"
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.id"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市区町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address"
                        inputTyle="text"
                        placeholder="市区町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="validate.address.isValid"
                        :errMsg.sync="validate.address.errMsg"
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                          :value.sync="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName"
                          inputTyle="text"
                          placeholder="番地建物名号室"
                          tooltip="40文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="40"
                          :isValid.sync="validate.buildingName.isValid"
                          :errMsg.sync="validate.buildingName.errMsg"
                          />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
              </div>
            </div>
          </template>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>廃棄物名称</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox
                name="disposal-kind"
                :value.sync="formValue.wasteInfo.wasteNameId"
                :validation="validateJustBlank"
                :isValid.sync="validate.wasteName.isValid"
                :errMsg.sync="validate.wasteName.errMsg"
              >
                <option :value="null"></option>
                <option
                  v-for="(opt, index) in wasteNameOpt"
                  :key="'jwi' + index"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>詳細名称</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                :value.sync="formValue.wasteInfo.detailWasteName"
                placeholder="詳細名称"
                tooltip="30文字以内で入力してください"
                :validation="validateMaxLen"
                :validateArg="30"
                :isValid.sync="validate.detailWasteName.isValid"
                :errMsg.sync="validate.detailWasteName.errMsg"
              />
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>廃棄物数量</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  inputType="number"
                  :value.sync="formValue.wasteInfo.wasteQuantity"
                  tooltip="廃棄物数量は数字で入力してください。"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.wasteQuantity.isValid"
                  :errMsg.sync="validate.wasteQuantity.errMsg"
                />
                <Selectbox
                  class="sm"
                  name="unit"
                  :value.sync="formValue.wasteInfo.wasteUnitTypeId"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.wasteUnitTypeId.isValid"
                  :errMsg.sync="validate.wasteUnitTypeId.errMsg"
                >
                  <option :value="null"></option>
                  <option
                    v-for="(opt, index) in wasteUnitOpt"
                    :key="'wu' + index"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>数量確定者</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox
                name="number-in-charge"
                :value.sync="formValue.wasteInfo.quantityConfirmBusinessTypeId"
                :validation="validateJustBlank"
                :isValid.sync="validate.quantityConfirmBusinessTypeId.isValid"
                :errMsg.sync="validate.quantityConfirmBusinessTypeId.errMsg"
              >
                <option :value="null"></option>
                <option
                  v-for="(opt, index) in confirmMemberOpt"
                  :key="'cm' + index"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>荷姿</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox
                name="package-name"
                :value.sync="formValue.wasteInfo.shapeTypeId"
                :validation="validateJustBlank"
                :isValid.sync="validate.shapeTypeId.isValid"
                :errMsg.sync="validate.shapeTypeId.errMsg"
              >
                <option :value="null"></option>
                <option
                  v-for="(opt, index) in shapeOpt"
                  :key="'shp' + index"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>荷姿数量</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  :value.sync="formValue.wasteInfo.shapeQuantity"
                  inputType="number"
                  :validation="validateNumber"
                  :isValid.sync="validate.shapeQuantity.isValid"
                  :errMsg.sync="validate.shapeQuantity.errMsg"
                />
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分方法</label>
            </div>
            <div class="c-inputWrap__items">
              <Selectbox
                name="package-name"
                :value.sync="formValue.wasteInfo.disposalWayTypeId"
                :validation="validateJustBlank"
              >
                <option value=""></option>
                <option
                  v-for="(opt, index) in disposalWayTypesOpt"
                  :key="'dwt' + index"
                  :value="opt.id"
                >
                  {{ opt.name }}
                </option>
              </Selectbox>
            </div>
          </div>

          <div v-if="isDisplayHarmfulSelection" class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有害物質名</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                v-for="(harmfulItem, itemIndex) in formValue.harmfulList"
                :key="`hmfl-item-${itemIndex}`"
                class="l-flex start"
              >
                <Selectbox
                  class="l-area"
                  name="package-name"
                  :value.sync="harmfulItem.id"
                  :validation="validateJustBlank"
                >
                  <option
                    v-for="(harmfulType, index) in harmfulTypes"
                    :key="'hmfl-' + index"
                    :value="harmfulType.id"
                  >
                    {{ harmfulType.name }}
                  </option>
                </Selectbox>
                <div
                  class="c-input__addBtn c-btn secondary delete sm"
                  @click="removeHarmfulTypeHandler(harmfulItem)"
                >
                  削除
                </div>
              </div>
              <div class="c-btn secondary add sm" v-if="formValue.harmfulList && formValue.harmfulList.length < 6" @click="addHarmfulTypeHandler">
                有害物質名を追加
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>連絡番号</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                v-for="(contact, contactIndex) in formValue.contactNoInfo"
                :key="`ctct-item-${contactIndex}`"
                class="l-flex start"
              >
                <InputText
                  class="l-area"
                  :value.sync="contact.contactNo"
                  inputTyle="text"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contactNo[contactIndex].isValid"
                  :errMsg.sync="validate.contactNo[contactIndex].errMsg"
                />
                <div
                  class="c-input__addBtn c-btn secondary delete sm"
                  @click="removeContactNoHandler(contact, contactIndex)"
                >
                  削除
                </div>
              </div>
              <div
                v-if="formValue.contactNoInfo.length < 3"
                class="c-btn secondary add sm"
                @click="addContactNoHandler"
              >
                連絡番号を追加
              </div>
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>備考</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                class="l-area"
                v-for="(taInfo, index) in formValue.remarksInfo"
                :key="'ta' + taInfo.id"
              >
                <InputText
                  class="l-area"
                  placeholder="備考は50バイト以内で入力してください。"
                  :value.sync="taInfo.detail"
                  inputType="text"
                  :validateArg="50"
                  :validation="validateMaxLen"
                  :change="onChangeTextareaHandler"
                  :isValid.sync="validate.remarks[index].isValid"
                  :errMsg.sync="validate.remarks[index].errMsg"
                />
                <div
                  class="c-input__addBtn c-btn secondary delete sm"
                  @click="removeTextareaHandler(taInfo.id)"
                >閉じる
                </div>
              </div>
              <div
                v-if="formValue.remarksInfo.length < 5"
                class="c-btn secondary func add"
                @click="addTextareaHandler"
              >
                テキストエリアを追加
              </div>
            </div>
          </div>

          <!-- 添付ファイル -->
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>添付ファイル</label>
            </div>
            <div class="c-inputWrap__items">
              <!-- 容量制限エラーメッセージ表示領域 -->
              <span v-if="manifestAttachedFileMsg" class="c-input__errMsg">{{
                manifestAttachedFileMsg
              }}</span>
              <template v-if="!formValue.manifestInfo || !formValue.manifestInfo.attachedFileName">
                <input
                  id="file-upload"
                  type="file"
                  @change="(e) => uploadManifestAttachedFile(e)"
                  ref="attachedFile"
                  accept=".pdf"
                />
                <label
                  class="c-btn secondary upload fileupload-sm"
                  for="file-upload"
                >
                  ファイルアップロード
                </label>
              </template>
              <template v-else>
                <div class="l-flex start">
                  <div class="upload-filename">
                    <span class="upload-filename__text" v-if="formValue.manifestInfo.attachedFilePath">
                      {{ formValue.manifestInfo.attachedFileName }}
                    </span>
                    <!-- アップロード済みのファイルはダウロードリンク表示 -->
                    <a v-else
                      class="c-text text-link c-entrustText__text"
                      @click="downloadManifestAttachedFile(formValue.manifestInfo.attachedFileName)"
                    >
                      <span class="upload-filename__text">
                        {{ formValue.manifestInfo.attachedFileName }}
                      </span>
                    </a>
                  </div>
                  <label
                    class="c-btn secondary delete sm"
                    @click="deleteManifestAttachedFile()"
                  >
                    削除
                  </label>
                </div>
              </template>
            </div>
          </div>

          <!-- WDS -->
          <div v-if="routeDetailInfo.wdsFileName" class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>WDS</label>
            </div>
            <div class="c-inputWrap__items">
              <a
                class="c-text text-link c-entrustText__text"
                @click="downloadWDS()"
              >
                {{ routeDetailInfo.wdsFileName }}
              </a>
            </div>
          </div>

          <!-- トン換算係数 -->
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>トン換算係数</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="l-flex start">
                <InputText
                  :value.sync="formValue.manifestInfo.conversionFactorKg"
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorKg.isValid"
                  :errMsg.sync="validate.conversionFactorKg.errMsg"
                /><span>/ kg</span>
              </div>
              <div class="l-flex start">
                <InputText
                  :value.sync="formValue.manifestInfo.conversionFactorM3"
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorM3.isValid"
                  :errMsg.sync="validate.conversionFactorM3.errMsg"
                /><span>/ m3</span>
              </div>
              <div class="l-flex start">
                <InputText
                  :value.sync="formValue.manifestInfo.conversionFactorLiter"
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorLiter.isValid"
                  :errMsg.sync="validate.conversionFactorLiter.errMsg"
                /><span>/ リットル</span>
              </div>
              <div class="l-flex start">
                <InputText
                  :value.sync="
                    formValue.manifestInfo.conversionFactorUnitNumber
                  "
                  class="l-area sm"
                  tooltip="半角数値で入力してください"
                  inputType="number"
                  :validation="validateNumberOrFloat"
                  :isValid.sync="validate.conversionFactorUnitNumber.isValid"
                  :errMsg.sync="validate.conversionFactorUnitNumber.errMsg"
                /><span>/ 個・台</span>
              </div>

            </div>
          </div>

          <!-- ルートラベル -->
          <div
            class="c-inputWrap"
            v-for="(manifestLabel, manifestLabelIndex) in formValue.labelInfo
              .manifestLabels"
            :key="'label-' + manifestLabelIndex"
          >
            <div class="c-inputWrap__label">
              <label>{{ manifestLabel.labelHeader }}</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputText
                  class="xlg"
                  inputType="text"
                  :value.sync="manifestLabel.labelValue"
                  :validation="validateJustBlank"
                />
                <span v-if="manifestLabel.labelUnit">
                  {{ manifestLabel.labelUnit }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.l-contents -->

      <!-- 運搬終了報告 -->
      <CollectReportList :wasteType="formValue.wasteType" :list="null" :type="collectReportDisplayType.BeforeReport" />

      <!-- 処分終了報告 -->
      <InterDisposalList :wasteType="formValue.wasteType" :list="null" :type="interDisposalReportDisplayType.BeforeReport" :finalDisposalRegistDate="''" />

      <!-- 最終処分終了報告 -->
      <DisposalReportList :wasteType="formValue.wasteType" :list="null" :type="disposalReportDisplayType.BeforeReport"/>
    </div>

    <FloatButtons>
      <div class="left">
        <div class="c-btn secondary small back" @click="backHandler">一覧へ戻る</div>
      </div>
      <div class="center">
        <div v-if="isOwnedManifest || this.isGenerator" class="c-btn secondary small" :class="{ disabled: this.isReservePending() || isDisableCancelConfirmHandlerButton || !formValue.isEdittable }" @click="cancelConfirmHandler">取消</div>
        <div v-if="isOwnedManifest || this.isGenerator" class="c-btn primary mid" :class="{ disabled: this.isReservePending() && this.isCollector }" @click="saveConfirmHandler">保存</div>
        <div class="c-btn primary small" @click="showChecksheetDownloadModal">受渡確認表印刷</div>
        <!-- 排出事業者のみが自分予約のマニフェストまたは運搬代理予約のマニフェストを交付できる -->
        <div v-if="this.isGenerator" class="c-btn primary small" :class="{ disabled: isDisableIssueButton || isExpiredManifest }" @click="issueConfirmHandler">交付</div>
        <div v-if="formValue.isCollectReportable && this.isCollector" class="c-btn primary small" :class="{ disabled: isDisableIssueButton }" @click="isCollectReportConfirmModalShow = true">運搬終了報告</div>
      </div>
    </FloatButtons>

    <!-- 取消確認モーダル -->
    <div class="c-modal" v-show="isCancelConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを取り消します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isCancelConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click.once="cancelHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!-- 保存確認モーダル -->
    <div class="c-modal" v-show="isSaveConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを修正します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isSaveConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="saveHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!-- 交付確認モーダル -->
    <div class="c-modal" v-show="isIssueConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを交付します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isIssueConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="issueHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!-- 運搬終了報告確認モーダル -->
    <div class="c-modal" v-show="isCollectReportConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          運搬終了報告を行います。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isCollectReportConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="collectReportWithAutoIssue">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!-- 登録モーダル -->
    <div class="c-modal lg" v-show="isChecksheetDownloadModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">

        <div class="c-modal__head">
          <h5 class="c-lead-s">受渡確認票印刷ダウンロード</h5>
          <span class="c-modal__head__close" @click="closeModalHandler"></span>
        </div>
        <div class="c-modal__body">
          <p class="u-txt-center">受け渡し確認票をダウンロードする形式を選択してください</p>
          <p class="u-txt-center" v-if="checksheetDownloadMessage">{{ checksheetDownloadMessage }}</p>

          <div
            v-for="cdt in checksheetDownloadTypes"
            :key="cdt.id"
            class="c-modal__foot"
          >
            <div>
              <div class="c-btn primary download-sm" :class="{ disabled: isDisableChecksheetDownload }" @click="checksheetDownloadHandler(cdt.value)">{{ cdt.label }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeModalHandler">閉じる</div>
        </div>
      </div>
    </div>

    <RouteSelectionModal
      v-bind:isRouteSelectModalShow="isRouteSelectModalShow"
      v-on:onClickCloseButton="isRouteSelectModalShow = false"
      v-bind:narrowedRoutesList="this.narrowedRoutesList"
      v-bind:jwnetItemsOpt="this.jwnetItemsOpt"
      v-bind:wasteTypeIdOpt="this.wasteTypeIdOpt"
      v-bind:industrialWasteJwnetTypeOpt="this.industrialWasteJwnetTypeOpt"
      v-bind:manifestTypeOpt="this.manifestTypeOpt"
      v-bind:routeModalSearchParam="this.routeModalSearchParam"
      v-bind:isEmptyRegistOrNotSetRoute="this.formValue.routeInfo.routeId === 0"
      v-bind:selectCollectCompanyList="this.selectCollectCompanyList"
      v-bind:collectCompanyList="this.collectCompanyList"
      v-bind:selectDisposalCompanyList="this.selectDisposalCompanyList"
      v-bind:disposalCompanyList="this.disposalCompanyList"
      v-bind:selectDisposalSiteList="this.selectDisposalSiteList"
      v-bind:disposalSiteList="this.disposalSiteList"
      v-on:clickSetRoute="clickSetRoute($event)"
      v-on:resetRouteModalSearchHandler="resetRouteModalSearchHandler()"
      v-on:routeModalSearchHandler="routeModalSearchHandler()"
    />

  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import InputTime from "@/components/parts/input/InputTime";
import CollectReportList, { CollectReportDisplayType } from "./CollectReportList.vue";
import InterDisposalList, { InterDisposalReportDisplayType } from "./InterDisposalList.vue";
import DisposalReportList, { DisposalReportDisplayType } from "./DisposalReportList.vue";
import RouteSelectionModal from "./RouteSelectionModal.vue";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import addressSearch from "../mixins/addressSearch";
import callApi from "../mixins/callApi";
import contactNo from "../mixins/contactNo";
import route from "../mixins/route";
import textarea from "../mixins/textarea";
import harmfulType from "../mixins/harmfulType";
import validate from "../mixins/validate";
import formatEditData from "../mixins/formatEditData";
import { API_URL, DOMAIN } from "@/const/index";
import { saveAs } from "file-saver";
import ToastMessage from "@/components/parts/ToastMessage";
import ManifestProgressBar from "./ManifestProgressBar";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import FloatButtons from "@/components/layout/FloatButtons";
import uploadFile from "@/mixin/uploadFile";
import downloadManifestAttachedFile from "@/pages/manifest/common/downloadManifestAttachedFile";

export default {
  name: "manifest-detail-before-issue",
  props: {
    manifestId: {
      type: Number,
    },
    isOwnedManifest: {
      type: Boolean,
    },
    formValue: {
      type: Object,
      required: true,
    },
    afterUpdateFunc: {
      type: Function
    },
  },
  components: {
    FloatButtons,
    ModalFooterButtons,
    ManifestProgressBar,
    ToastMessage,
    InputText,
    Selectbox,
    InputDate,
    InputTime,
    CollectReportList,
    InterDisposalList,
    DisposalReportList,
    RouteSelectionModal,
  },
  mixins: [
    addressSearch,
    common,
    contactNo,
    validation,
    callApi,
    route,
    textarea,
    harmfulType,
    validate,
    formatEditData,
    uploadFile,
    downloadManifestAttachedFile,
  ],

  data() {
    return {
      selectedAddress: {},
      isEdittedForm: false,
      isIssueConfirmModalShow: false,
      isSaveConfirmModalShow: false,
      isRouteSelectModalShow: false,
      isCollectReportConfirmModalShow: false,
      selectedRecurrying: "",
      collectionDateLimit: null,
      collectReportLimit: null,
      errMsgs: [],
      disposalReportDisplayType: DisposalReportDisplayType,
      interDisposalReportDisplayType: InterDisposalReportDisplayType,
      collectReportDisplayType: CollectReportDisplayType,
      selectedJwnetItem: {},
      isCancelConfirmModalShow: false,
      toastMsg: "",
      backPath: "",
      processing: false,
      isChecksheetDownloadModalShow: false,
      checksheetDownloadTypes: [
        {
          label: "PDFダウンロード(複数一括)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF,
        },
        {
          label: "PDFダウンロード(単票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET,
        },
        {
          label: "PDFダウンロード(連票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET,
        },
        {
          label: "EXCELダウンロード",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.EXCEL_ZIP,
        },
      ],
      wasteTypeIdOpt: [
        { id: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE , name: "産業廃棄物" },
        { id: DOMAIN.WASTE_TYPE.VALUABLES , name: "有価物" },
        { id: DOMAIN.WASTE_TYPE.COMMON_WASTE , name: "一般廃棄物" },
      ],
      industrialWasteJwnetTypeOpt: [
        { id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_NOT_SPECIAL_INDUSTRIAL_WASTE , name: "普通産業廃棄物" },
        { id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE , name: "特別管理産業廃棄物" },
      ],
      manifestTypeOpt: [
        { id: DOMAIN.MANIFEST_TYPE.DIGITAL , name: "電子" },
        { id: DOMAIN.MANIFEST_TYPE.ANALOG , name: "紙マニフェスト" },
      ],
      // ルート選択肢モーダル検索パラメータ
      routeModalSearchParam: {
        jwnetItemTypeId: "", // 廃棄物種類ID
        routeName: "", // ルート名称
        wasteName: "", // 廃棄物名称
        collectCompanyId: null, // 運搬事業者Id
        disposalCompanyId: null, // 処分事業者Id
        disposalSiteId: null, // 処分場ID
        wasteTypeIdList: [],//廃棄物区分
        manifestTypeIdList: [], // マニフェスト区分
        industrialWasteJwnetTypeIdList: [],
        isFavorite: false, // お気に入りフラグ
      },
      isAbleChangeRoute: false,
      isDisableChecksheetDownload: false,
      checksheetDownloadMessage: "",
      manifestAttachedFileMsg: "",
      isGenerateMemberSelect: true,
      isRegistMemberSelect: true
    };
  },

  methods: {
    // 「ルート選択」ボタンを押すと、選択モーダルを表示する
    showRouteSelectModal() {
      // 設定した条件があればそのままにする、ない場合、default値で設定
      this.setRouteSelectModalSearchParam();
      this.isRouteSelectModalShow = true;
      this.getRouteListApi(this.formValue.manifestInfo.generateStoreInfo.id, true);
    },

    // 設定した条件があればそのままにひょうじする
    setRouteSelectModalSearchParam() {
      if (this.formValue.routeInfo.routeId === 0) {
        //空登録または、ルート未設定で予約されたマニフェストに対しては、ルート選択モーダルにて、廃棄物区分＝産業廃棄物、マニフェスト区分＝電子マニフェストのチェックを固定として、検索する。
        if (!this.routeModalSearchParam.wasteTypeIdList.length) {
          this.routeModalSearchParam.wasteTypeIdList = [1];
        }
        if (!this.routeModalSearchParam.manifestTypeIdList.length) {
          this.routeModalSearchParam.manifestTypeIdList = [1];
        }
      }
      const currentSearchParam = this.routeModalSearchParam;
      this.routeModalSearchParam = {
        jwnetItemTypeId: currentSearchParam.jwnetItemTypeId !== "" ? currentSearchParam.jwnetItemTypeId : "",
        routeName: currentSearchParam.routeName !== "" ? currentSearchParam.routeName : "",
        wasteName: currentSearchParam.wasteName !== "" ? currentSearchParam.wasteName : "",
        collectCompanyId: currentSearchParam.collectCompanyId,
        disposalCompanyId: currentSearchParam.disposalCompanyId,
        disposalSiteId: currentSearchParam.disposalSiteId,
        wasteTypeIdList: currentSearchParam.wasteTypeIdList.length > 0 ? currentSearchParam.wasteTypeIdList : [],
        manifestTypeIdList: currentSearchParam.manifestTypeIdList.length > 0 ? currentSearchParam.manifestTypeIdList : [],
        industrialWasteJwnetTypeIdList: currentSearchParam.industrialWasteJwnetTypeIdList.length > 0 ? currentSearchParam.industrialWasteJwnetTypeIdList : [],
        isFavorite: currentSearchParam.isFavorite !== false ? currentSearchParam.isFavorite : false,
      }
    },

    // ルート選択肢モーダルに「検索条件をリセット」ボタンを押すと
    initRouteModalSearchParam() {
      this.routeModalSearchParam = {
        jwnetItemTypeId: "",
        routeName: "",
        wasteName: "",
        collectCompanyId: null,
        disposalCompanyId: null,
        disposalSiteId: null,
        wasteTypeIdList: this.formValue.routeInfo.routeId === 0 ? [1] : [],//廃棄物区分
        manifestTypeIdList: this.formValue.routeInfo.routeId === 0 ? [1] : [], // マニフェスト区分
        industrialWasteJwnetTypeIdList: [],
        isFavorite: false,
      }
    },

    // ルート選択肢モーダルの検索情報をクリアする
    resetRouteModalSearchHandler(){
      this.initRouteModalSearchParam()
    },

    // ルート選択肢モーダルに「検索」ボタンを押す処理
    routeModalSearchHandler() {
      this.getRouteListApi(this.formValue.manifestInfo.generateStoreInfo.id, true)
    },

    init() {
      this.backPath = this.$route.query.transitionSource;
      // 初期表示に必要なAPIを呼ぶ
      this.getRouteListApi(this.formValue.manifestInfo.generateStoreInfo.id, true);

      if (this.formValue.wasteInfo.wasteName == null) {
        this.formValue.wasteInfo.wasteNameId = "";
      } else {
        this.formValue.wasteInfo.wasteNameId = this.formValue.wasteInfo.wasteName.id;
      }

      if (this.formValue.wasteInfo.wasteUnitType != null) {
        this.formValue.wasteInfo.wasteUnitTypeId = this.formValue.wasteInfo.wasteUnitType.id;
      }

      if (this.formValue.wasteInfo.quantityConfirmBusinessType != null) {
        this.formValue.wasteInfo.quantityConfirmBusinessTypeId = this.formValue.wasteInfo.quantityConfirmBusinessType.id;
      }

      if (this.formValue.routeInfo.jwnetItem == null) {
        this.formValue.routeInfo.jwnetItem = {
          id: null,
          name: "",
        }
      }

      if (this.formValue.anyGenerateStoreInfo == null) {
        this.formValue.anyGenerateStoreInfo = {
          anyGenerateStoreName: "",
          anyGenerateStoreAddress: {
            zipCode: "",
            prefecturesId: "",
            address: "",
            buildingName: "",
          },
        };
      } else {
        this.formValue.anyGenerateStoreInfo = {
          anyGenerateStoreName: this.formValue.anyGenerateStoreInfo.anyGenerateStoreName ? this.formValue.anyGenerateStoreInfo.anyGenerateStoreName : "",
          anyGenerateStoreAddress: this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress ? this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress : {
                zipCode: "",
                prefecturesId: "",
                address: "",
                buildingName: "",
              }
        }
      }

      if (this.formValue.routeInfo.routeId) {
        this.$set(this.formValue.manifestInfo, 'routeId', this.formValue.routeInfo.routeId);
        // this.formValue.manifestInfo.routeId = this.formValue.routeInfo.routeId;
        const route = this.formValue.routeInfo;
        this.routeCompanyName.generateStore = route.generateStore.name;
        this.jwnetItemTypeName = route.jwnetItem.name;
        this.routeCompanyName.collectCompany = route.collectCompanys
          .map((cc) => cc.name)
          .join(",");

        if (route.disposalSite != null) {
          this.routeCompanyName.disposalSite = route.disposalSite.name;
        } else {
          this.routeCompanyName.disposalSite = "";
        }

        this.getRouteShapeTypeApi(route.routeId).then(() => {
          this.setFormShapeTypeId()
        })
        this.getRouteDisposalWayTypeApi(route.routeId).then(() => {
          this.setFormDisposalWayTypesApi()
        })

        this.getRouteInfoApi(this.formValue.routeInfo.routeId).then(() => {

          const r = this.routeDetailInfo;
          // CBA品目IDを元にCBA品目別廃棄物名称選択肢取得APIを呼び出す
          this.getWasteNameCBAItemApi(r.cbaItemInfo.id);
        });
      } else {
        this.getDisposalWayTypesApi().then(() => {
          this.setFormDisposalWayTypesApi()
        })
        this.getShapeApi().then(() => {
          this.setFormShapeTypeId()
        })
      }

      this.$set(this.validate, 'contactNo', this.formValue.contactNoInfo.map(() => {
          return {
            isValid: true,
            errMsg: "",
          };
        }));

      this.validate.remarks = [];
      this.formValue.remarksInfo.forEach(() => {
        this.validate.remarks.push({
          isValid: true,
          errMsg: ""
        });
      });


      this.getMemberOptApi(this.formValue.routeInfo.generateStore.id);
      this.getEntrustJwnetItemsApi(this.formValue.routeInfo.generateStore.id);
      this.getWasteUnitApi();
      this.getConfirmMemberApi(this.formValue.routeInfo.routeId);
      this.getPrefecturesApi();
      this.getHarmfulTypesApi();

      this.checkCollectionLimit();
      this.checkCollectReportLimit();

      // ルート変更可能かどうか
      this.isAbleChangeRoute = this.formValue.routeInfo.routeId === 0;
    },
    // フォームに荷姿IDを設定する
    setFormShapeTypeId() {
      if (this.formValue.wasteInfo.shapeType != null && this.shapeOpt != null && this.shapeOpt.filter(s=> s.id === this.formValue.wasteInfo.shapeType.id).length) {
        this.formValue.wasteInfo.shapeTypeId = this.formValue.wasteInfo.shapeType.id;
      }
    },
    // フォームに処分方法IDを設定する
    setFormDisposalWayTypesApi() {
      if (this.formValue.wasteInfo.disposalWayType != null && this.disposalWayTypesOpt != null && this.disposalWayTypesOpt.filter(d => d.id === this.formValue.wasteInfo.disposalWayType.id).length) {
        this.formValue.wasteInfo.disposalWayTypeId = this.formValue.wasteInfo.disposalWayType.id;
      }
    },
    validateRequireNumber(value) {
      return value !== null && String(value).length > 0;
    },
    // 編集API
    editPutApi(data) {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止

      return axios
        .put(`${API_URL.MANIFEST.INFO}${this.manifestId}`, data)
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isSaveConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
          throw err;
        });
    },

    // 交付API
    issuePutApi(data) {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止

      axios
        .put(`${API_URL.MANIFEST.INFO}${this.manifestId}`, data)
        .then((res) => {
          const manifestId = res.data.manifestId;
          this.processing = false; // 2重submit解除
          this.isIssueConfirmModalShow = false;
          this.$router.push(`/manifest/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`, () => {
              this.afterUpdateFunc(manifestId).then(() => {
                this.init();
              });
            });
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isIssueConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    // 保存、モーダル内のOK押下
    saveHandler() {
      // データを編集用にフォーマットしたものを返す
      this.formatEditData(false)
        .then((formattedData) => {
        this.editPutApi(formattedData)
            .then((res) => {
          const manifestId = res.data.manifestId;
          this.processing = false; // 2重submit解除
          this.isSaveConfirmModalShow = false;
          this.$router.push(`/manifest/${manifestId}?transitionSource=${this.backPath}`, () => {
              this.$router.go()
        });
})
      });
    },

    // 交付、モーダル内のOK押下
    issueHandler() {
      // データを編集用にフォーマットしたものを返す
      this.formatEditData(true)
        .then((formattedData) => {
        this.issuePutApi(formattedData);
      });
    },

    // 保存押下
    saveConfirmHandler() {

      this.errMsgs = [];
      this.runValidateForSave().then((isValidForm) => {
        if (!isValidForm) {
          this.processing = false;
          this.errMsgs.push("入力に誤りがあります。");
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        this.isSaveConfirmModalShow = true;

      });
    },

    // 交付押下
    issueConfirmHandler() {
      this.errMsgs = [];
      this.runValidate().then((isValidForm) => {
        if (!isValidForm) {
          this.processing = false;
          this.errMsgs.push("入力に誤りがあります。");
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        this.isIssueConfirmModalShow = true;

      });
    },

    // 交付期限のチェック（日付差分）
    checkCollectionLimit() {
      const today = moment();
      const collectionDate = moment(this.formValue.manifestInfo.collectionDate);
      this.collectionDateLimit = collectionDate.diff(today, "days");
    },
    // 報告期限のチェック（日付差分）
    checkCollectReportLimit() {
      const today = moment();
      const collectionDate = moment(
        this.formValue.manifestInfo.collectReportDeadlineDate
      );
      this.collectReportLimit = collectionDate.diff(today, "days");
    },
    clickSetRoute(routeId) {
      this.formValue.manifestInfo.routeId = routeId;
      this.isRouteSelectModalShow = false;
      this.getConfirmMemberApi(routeId)
      this.onChangeRouteHandler();
    },
    // ルート選択時
    onChangeRouteHandler() {
      // 画面先頭のエラーメッセージを消す
      this.errMsgs = [];
      if (this.formValue.manifestInfo.routeId) {
        const route = this.narrowedRoutesList.filter(
          (r) => r.routeId === this.formValue.manifestInfo.routeId
        )[0];
        this.routeCompanyName.generateStore = route.generateStore.name;
        this.routeCompanyName.collectCompany = route.collectCompanys
          .map((cc) => cc.name)
          .join(",");
        this.routeCompanyName.disposalSite = route.disposalSite.name;
        this.getRouteInfoApi(this.formValue.manifestInfo.routeId).then(() => {
          // もともとルートで設定した情報を初期化する
          this.resetRouteInfoFromFormValue(this.formValue);
          // バリデーションチェックリセット
          this.resetValidateRouteInfo(this.validate);
          // 現状で選択したルート情報で値を設定する
          this.setRouteInfoValToFormVal();
        });
        this.getRoutePriorconsultationAlert()
        this.jwnetItemTypeName = route.jwnetItemType.name;
        this.getRouteShapeTypeApi(route.routeId);
        this.getRouteDisposalWayTypeApi(route.routeId);
      }
    },

    // ルート情報から設定する項目をリセット
    resetRouteInfoFromFormValue (f) {
      f.wasteInfo.jwnetItemTypeId = null;
      f.wasteInfo.wasteNameId = null;
      f.wasteInfo.detailWasteName = null;
      f.wasteInfo.wasteQuantity = null;
      f.wasteInfo.wasteUnitTypeId = null;
      f.wasteInfo.quantityConfirmBusinessTypeId = null;
      f.wasteInfo.shapeTypeId = null;
      f.wasteInfo.shapeQuantity = null;
      f.wasteInfo.disposalWayTypeId = null;
      f.harmfulInfo = [];
      f.contactNoInfo = [];
      f.remarksInfo = [];
      f.manifestInfo.conversionFactorKg = null;
      f.manifestInfo.conversionFactorM3 = null;
      f.manifestInfo.conversionFactorLiter = null;
      f.manifestInfo.conversionFactorUnitNumber = null;

      // 排出事業場情報が変更できるルートに対して任意事業場情報もリセット
      f.anyGenerateStoreInfo = {
        anyGenerateStoreName: "",
        anyGenerateStoreAddress: {
          zipCode: "",
          prefecturesId: "",
          address: "",
          buildingName: "",
        },
      };
    },

    // ルート情報から設定する項目のバリデーションをリセット
    resetValidateRouteInfo(v) {
      v.wasteQuantity = { isValid: true, errMsg: "" };
      v.detailWasteName = { isValid: true, errMsg: "" };
      v.wasteUnitTypeId = { isValid: true, errMsg: "" };
      v.zipCode = { isValid: true, errMsg: "" };
      v.prefecture = { isValid: true, errMsg: "" };
      v.address = { isValid: true, errMsg: "" };
      v.contactNo = [];
      v.remarks = [];
      v.conversionFactorKg = { isValid: true, errMsg: "" };
      v.conversionFactorM3 = { isValid: true, errMsg: "" };
      v.conversionFactorLiter = { isValid: true, errMsg: "" };
      v.conversionFactorUnitNumber = { isValid: true, errMsg: "" };
      v.anyGenerateStoreName = { isValid: true, errMsg: "" };
      v.zipCode = { isValid: true, errMsg: "" };
      v.prefecture = { isValid: true, errMsg: "" };
      v.address = { isValid: true, errMsg: "" };
    },

    // ルート情報APIで取得した値を適用
    setRouteInfoValToFormVal() {
      const f = this.formValue;
      const r = this.routeDetailInfo;
      const v = this.validate;

      f.routeInfo.jwnetItem.id = r.jwnetItemTypeInfo.id; // 廃棄物種類
      f.wasteInfo.wasteNameId = r.wasteNameInfo.id; // 廃棄物名称Id
      f.wasteInfo.detailWasteName = r.detailWasteName;
      f.manifestInfo.conversionFactorKg = r.conversionFactorKg;
      f.manifestInfo.conversionFactorLiter = r.conversionFactorLiter;
      f.manifestInfo.conversionFactorM3 = r.conversionFactorM3;
      f.manifestInfo.conversionFactorUnitNumber = r.conversionFactorUnitNumber;
      f.wasteInfo.wasteQuantity = r.plansQuantity; // 廃棄物数量
      if (r.plansWasteUnitType) {
        f.wasteInfo.wasteUnitTypeId = r.plansWasteUnitType.id; // 廃棄物単価
      }
      if (r.quantityConfirmBusinessType) {
        f.wasteInfo.quantityConfirmBusinessTypeId =
          r.quantityConfirmBusinessType.id; // 数量確定者
      }
      if (r.shapeType) {
        f.wasteInfo.shapeTypeId = r.shapeType.id; // 荷姿
      }
      f.wasteInfo.shapeQuantity = r.shapeQuantity; // 荷姿数量
      if (r.disposalWayType) {
        f.wasteInfo.disposalWayTypeId = r.disposalWayType.id; // 処分方法
      }


      if (f.contactNoInfo == null || f.contactNoInfo.length === 0) {
        this.$set(f, 'contactNoInfo', r.contactNoInfo);
        this.$set(v, 'contactNo', r.contactNoInfo.map(() => {
            return { isValid: true, errMsg: "" }
          }));
      }

      if (r.harmfulInfo && r.harmfulInfo.length > 0) {
        f.harmfulList = r.harmfulInfo;
      }


      if (f.remarksInfo == null || f.remarksInfo.length === 0) {
        let remarks = [];
        r.remarksInfo.forEach(r => remarks.push({"id": r.id, "detail": this.toFullWidthString(r.remarks)}));
        this.$set(f, 'remarksInfo', remarks);
        this.$set(v, 'remarks', r.remarksInfo.map(() => {
            return { isValid: true, errMsg: "" }
          }));
      }

      this.routeLabels = r.labelInfo; // ラベル
      this.$set(f, 'labelInfo', { manifestLabels: r.labelInfo.map((labelInfo) => {
          return {
            labelId: labelInfo.labelId,
            labelHeader: labelInfo.labelHeader,
            labelUnit: labelInfo.labelUnit,
            labelValue: labelInfo.labelDefaultValue,
            labelTypeId: labelInfo.labelTypeId,
          };
        })});

      // CBA品目IDを元にCBA品目別廃棄物名称選択肢取得APIを呼び出す
      this.getWasteNameCBAItemApi(r.cbaItemInfo.id);
    },
    downloadWDS() {
      this.getWDSDownloadApi(this.routeDetailInfo.routeId, this.routeDetailInfo.wdsFileName);
    },
    deliveryChecksheetHandler() {
      this.postDeliveryChecksheetApi(this.manifestId)
        .then((res) => {
        const blob = new Blob([res.data], {
          type: res.data.type
        });
        const disposition = res.headers['content-disposition'];
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          const filename = matches[1].replace(/['"]/g, '').replace("UTF-8", '');
          saveAs(blob, filename);
        } else {
          saveAs(blob, 'ukewatasi.zip');
        }
      });
    },
    isNotFormValid() {
      const v = this.validate;
      return !v.collectionDate.isValid ||
        !v.registMember.isValid ||
        !v.generateMember.isValid ||
        !v.jwnetItem.isValid ||
        !v.wasteName.isValid ||
        !v.wasteQuantity.isValid ||
        !v.quantityConfirmBusinessTypeId.isValid ||
        !v.shapeTypeId.isValid;
    },
    isNotReservedStatus() {
      return this.formValue.manifestInfo.manifestStatusInfo.id !== DOMAIN.MANIFEST_STATUS.RESERVED;
    },
    isReservePending() {
      return this.formValue.manifestInfo.manifestStatusInfo.id === DOMAIN.MANIFEST_STATUS.RESERVE_PENDING;
    },


    // 戻る押下
    backHandler() {
      if (this.backPath === "undefined") {
        this.$router.push('/manifests/all');
      } else if (this.backPath) {
        this.$router.push(this.backPath);
      } else {
        this.$router.push('/manifests/all');
      }
    },

    // 取消押下
    cancelConfirmHandler() {
      this.errMsgs = [];
      const manifestStatusId = this.formValue.manifestInfo.manifestStatusInfo.id;

      // マニフェストステータスが、修正中(23)、修正承認待ち（manifest_status_id = 22,23,32,33,34,35,42,43,44)の場合、エラーとなります
      if (manifestStatusId === DOMAIN.MANIFEST_STATUS.MANIFEST_FIX_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.MANIFEST_FIXING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_COLLECT_REPORT_FIX
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_FIX_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_DELETE_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_COLLECT_REPORT_DELETE
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_DISPOSAL_REPORT_FIX
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_FIX_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_DELETE_PENDING) {

        this.errMsgs.push("マニフェストが修正中のため、取消できません。");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_DELETE) {
        // 削除中（91)の場合もエラーとする
        this.errMsgs.push("マニフェストが削除中のため、取消できません。");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.isCancelConfirmModalShow = true;
      }
    },

    // 取消、モーダル内のOK押下
    cancelHandler() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止

      axios
        .patch(API_URL.MANIFEST.STATUS_UPDATE, {
          manifestIdList: [{ manifestId: this.manifestId }],
          manifestStatusId: DOMAIN.MANIFEST_STATUS.DELETE,
        })
        .then(() => {
          this.isCancelConfirmModalShow = false;
          this.backHandler();
        })
        .catch((err) => {
          this.isCancelConfirmModalShow = false;
          this.processing = false; // 2重submit解除
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    clickCollectReportButton() {
      if (!this.runValidateForSave()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      this.isCollectReportConfirmModalShow = true;
    },
    collectReportWithAutoIssue() {
      this.formatEditData(false)
        .then((formattedData) => {
        this.editPutApi(formattedData)
            .then((res) => {
          const manifestId = res.data.manifestId;
          this.processing = false; // 2重submit解除
          this.isCollectReportConfirmModalShow = false;

          // 終了報告の画面に遷移
          this.$router.push(`/manifest/collect/report/${manifestId}`);
        })
      });
    },

    closeModalHandler() {
      this.isChecksheetDownloadModalShow = false;
    },

    // 受渡確認票印刷押下
    showChecksheetDownloadModal() {
      this.isChecksheetDownloadModalShow = true;
    },

    // 受渡確認票をダウンロード
    checksheetDownloadHandler(downloadTypeId) {
      this.errMsgs = [];

      this.isDisableChecksheetDownload = true;
      this.checksheetDownloadMessage = `${this.checksheetDownloadTypes.find(t => t.value === downloadTypeId).label}を行なっています。少々お待ちください。`;
      axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET
            , { downLoadTypeId: downloadTypeId, manifestIdList: [{manifestId: this.manifestId}] }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          if (downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET) {
            saveAs(blob, `受渡確認表(単票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET) {
            saveAs(blob, `受渡確認表(連票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF) {
            saveAs(blob, `PDFダウンロード(複数一括)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else {
            saveAs(blob, `受渡確認表_${moment().format("YYYYMMDDHHmmss")}.zip`);
          }
        })
        .then(() => {
          // ダウンロードボタンは活生にする
          this.isDisableChecksheetDownload = false;
          this.checksheetDownloadMessage = "";
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },

    /** 添付ファイルアップロード */
    uploadManifestAttachedFile(e) {
      this.manifestAttachedFileMsg = "";

      const sizeLimit = 1024 * 1024 * 5;
      const file = e.target.files[0] || e.dataTransfer.files[0];

      // PDFファイル種類チェック
      if (file.type !== "application/pdf") {
        this.manifestAttachedFileMsg = "PDFファイルを選択してください。";
        return;
      }

      if (file.size > sizeLimit) {
        this.manifestAttachedFileMsg = `ファイルサイズは5MB以下にしてください。`;
        return;
      }

      // PDFファイル名は50文字列以内で設定するチェック
      if (file.name.length > 50) {
        this.manifestAttachedFileMsg = "ファイル名は50文字列以内にしてください。";
        return;
      }

      this.uploadFileApi(file)
        .then((path) => {
          this.formValue.manifestInfo.attachedFilePath = path;
          this.formValue.manifestInfo.attachedFileName = file.name;
          this.manifestAttachedFileMsg = "";
        })
        .catch((error) => {
          console.log(error);
          this.manifestAttachedFileMsg = error;
        });
    },

    /** 追加したPDFを削除する */
    deleteManifestAttachedFile() {
      this.formValue.manifestInfo.attachedFilePath = null;
      this.formValue.manifestInfo.attachedFileName = null;
      if (this.$refs.attachedFile) {
        this.$refs.attachedFile.value = null;
      }
      this.manifestAttachedFileMsg = "";
    },

    /** PDFダウンロード押下 */
    downloadManifestAttachedFile(attachedFileName) {
      this.downloadManifestAttachedFileApi(this.manifestId, attachedFileName);
    },

    resetGenerateMemberForm() {
      this.formValue.manifestInfo.generateMember = {
        id: "",
        name: "",
      };
    },

    resetRegistMemberForm() {
      this.formValue.manifestInfo.registMember = {
        id: "",
        name: "",
      };
    }
  },

  computed: {
    isGenerator() {
      return this.$store.getters["userInfo/isGenerator"];
    },
    isCollector() {
      return this.$store.getters["userInfo/isCollector"];
    },
    isDisposer() {
      return this.$store.getters["userInfo/isDisposer"];
    },
    isDisplayHarmfulSelection: function() {
      if (this.formValue.routeInfo.jwnetItem) {
        const selectedJwnetItemId = this.formValue.routeInfo.jwnetItem.id;
        const filteredJwnetItems = this.jwnetItemsOpt.filter(jwnetItem => jwnetItem.id === selectedJwnetItemId);

        if (filteredJwnetItems.length > 0) {
          return filteredJwnetItems[0].isHarmful;
        }
      }

      return false;

    },
    isIssueDeadlineWarning() {
      return this.formValue.manifestInfo.issueAlertType === 1;
    },
    isIssueDeadlineAlert() {
      return this.formValue.manifestInfo.issueAlertType === 2;
    },
    isDisableIssueButton() {
      return this.isNotReservedStatus() || this.isNotFormValid() || !this.formValue.wasteInfo.wasteNameId;
    },
    isDisableCancelConfirmHandlerButton() {
      return this.formValue.manifestInfo.manifestStatusInfo.id === DOMAIN.MANIFEST_STATUS.UNDER_RESERVE;
    },
    isCloseToExpire() {
      if (this.formValue == null || this.formValue.manifestInfo == null || this.formValue.manifestInfo.expirationDate == null) {
        return false;
      }
      const expiration30BeforeDay = moment(this.formValue.manifestInfo.expirationDate).subtract(30, 'd');
      const currentDay = moment();
      return currentDay.isSameOrAfter(expiration30BeforeDay);
    },
    isExpiredManifest() {
      return (this.formValue.manifestInfo.expirationDate && moment().isAfter(this.formValue.manifestInfo.expirationDate) && this.formValue.manifestInfo.manifestStatusInfo.id === DOMAIN.MANIFEST_STATUS.RESERVED)
    }
  },
  watch: {
    'formValue.manifestInfo.collectionDate'(newVal) {
      console.log(newVal)
      this.getRoutePriorconsultationAlert()
    },
    formValue: {
      handler: function () {
        this.isEdittedForm = true;
      },
      deep: true,
    },
    selectedAddress() {
      const filteredPrefectures = this.prefectures.filter((prefecture) => prefecture.name === this.selectedAddress.address1);
      if (filteredPrefectures.length > 0) {
        this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId = filteredPrefectures[0].id;
      }

      this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address = this.selectedAddress.address2;
      this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName = this.selectedAddress.address3;
    },
  },

  beforeMount() {
    if (this.formValue.manifestInfo.registMember === null) {
      this.resetRegistMemberForm()
    } else {
      if (this.formValue.manifestInfo.registMember.id === null) {
        this.isRegistMemberSelect = false
      }
    }

    if (this.formValue.manifestInfo.generateMember === null) {
      this.resetGenerateMemberForm()
    } else {
      if (this.formValue.manifestInfo.generateMember.id === null) {
        this.isGenerateMemberSelect = false
      }
    }
  },

  mounted() {
    this.init();
    this.getRoutePriorconsultationAlert();
    if (this.isAbleChangeRoute) {
      this.getDisposalSiteApi();
      this.getCollectCompanyForGenerate();
      this.getDisposalCompany();
    }
  },
};
</script>

<style scoped>
.c-select-input {
  padding-left: 0;
  width: 22rem;
  margin-top: 2.7rem;
}
</style>
